$app-background: #121212;
$app-text: #ffffff;
$heading-color: #ffcc00; // Accent color for headings
$subheading-color: #b3b3b3;
$tagline-color: #999999;
$spinner-color: #ffcc00;

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: $app-background;
}

.appContainer {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  background-color: $app-background;
  color: $app-text;

  @media only screen and (max-width: 600px) {
    flex-direction: column-reverse;
    height: auto;
  }
}

.leftContainer {
  padding: 35px;
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 820px;

  @media only screen and (max-width: 600px) {
    padding: 20px;
  }
}

.mainHeading {
  font-size: 38px;
  color: $heading-color;

  @media only screen and (max-width: 600px) {
    font-size: 32px;
    margin-bottom: 14px;
  }
}

.subHeading {
  font-size: 20px;
  margin-bottom: 0.5rem;
  color: $subheading-color;

  @media only screen and (max-width: 600px) {
    font-size: 18px;
    margin-bottom: 14px;
  }
}

.bioText {
  line-height: 1.8;
}

.tagline {
  text-align: right;
  font-size: 11px;
  color: $tagline-color;
}

.spinner-border {
  width: 3rem;
  height: 3rem;
  border: 0.3em solid $spinner-color;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 0.75s linear infinite;
  margin: auto;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
